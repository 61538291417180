import React from 'react';
import { useTranslation } from 'react-i18next';

import { IonImg } from '@ionic/react';

import BrandGobazarLongIcon from '@/components/icons/brands/BrandGobazarLongIcon.tsx';
import { GOBAZAR_CONFIG } from '@/utils/configs/gobazarConfig.ts';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="mt-auto px-6 pb-12 pt-3 lg:p-0">
      <div className="flex flex-col items-center gap-6 border-t border-[#2125291F] pt-3 lg:bg-white lg:p-16 lg:pt-12">
        <div className="application-width mx-auto flex w-full flex-col justify-between gap-6 lg:flex-row lg:gap-12">
          <div className="hidden flex-col justify-between gap-6 lg:flex">
            <BrandGobazarLongIcon className="h-8 w-38" />
            <p className="text-16 text-[#383842]">© 2024 GoBazar. {t('common.rightsReserved')}.</p>
          </div>
          <div className="hidden max-w-[270px] flex-row items-end gap-4 lg:flex">
            <a
              className="flex-1"
              href={GOBAZAR_CONFIG.MOBILE_APPS.SELLER.PLAY_STORE}
              rel="noreferrer"
              target="_blank"
            >
              <IonImg
                alt="Get it on Google Play"
                className="object-contain object-bottom"
                src="/images/home/home-get-play-store.webp"
              />
            </a>

            <a
              className="flex-1"
              href={GOBAZAR_CONFIG.MOBILE_APPS.SELLER.APP_STORE}
              rel="noreferrer"
              target="_blank"
            >
              <IonImg
                alt="Download on the App Store"
                className="object-contain object-bottom"
                src="/images/home/home-get-app-store.webp"
              />
            </a>
          </div>

          <div className="flex flex-col items-center justify-between gap-1 text-center text-15 font-4 text-[#21252947] lg:items-end lg:text-right [&_a:hover]:text-gobazar-400 [&_a:hover]:underline">
            <a
              className="p-0.5"
              href="https://landing.gobazar.io/terms/"
              rel="noreferrer"
              target="_blank"
            >
              {t('common.termsOfService')}
            </a>
            <a
              className="p-0.5"
              href="https://landing.gobazar.io/terms/"
              rel="noreferrer"
              target="_blank"
            >
              {t('common.privacyPolicy')}
            </a>
            <a
              className="p-0.5"
              href="https://landing.gobazar.io/offer/"
              rel="noreferrer"
              target="_blank"
            >
              {t('common.publicOffer')}
            </a>
          </div>

          <p className="text-center text-16 font-4 leading-6 text-[#21252985] lg:hidden">
            {t('common.LTD')} «Bazaar Concierge»
          </p>
        </div>
      </div>
    </div>
  );
}
